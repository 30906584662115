<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="客户">
                <a-input v-model="queryParam.clientName" placeholder="请输入客户姓名或手机号"/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="经纪人">
                <a-input v-model="queryParam.brokerName" placeholder="请输入经纪人姓名或手机号"/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">                    
                <a-form-item
                  label="楼盘名称">
                  <a-select
                    show-search
                    placeholder="请选择楼盘名称"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    v-decorator="[
                      'saleStatus'
                    ]"
                    @change="handleSelectChange"
                    v-model="queryParam.houseId"
                    allowClear>
                    <a-select-option :value="item.id" v-for="(item,index) in housesList" :key="index">{{ item.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetReload">重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <s-table
        ref="table"
        size="default"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data="loadData"
        :alert="true"
        :totalCount="totalCount"
        :scroll="defaultScroll"
        showPagination="auto"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="status" slot-scope="text">
          <a-badge :status="text | statusTypeFilter" :text="text | statusFilter" />
        </span>
        <span slot="description" slot-scope="text">
          <ellipsis :length="4" tooltip>{{ text }}</ellipsis>
        </span>
        <span slot="naturalVisit" slot-scope="text">{{ text === 0 ? '自然到访' : '经纪人报备' }}</span>

        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleEdit(record)">查看</a>
          </template>
        </span>
      </s-table>

      <!-- evidence info -->
      <a-drawer
        :title="drawer ? '流水号:'+ drawer.id : '流水号: -'"
        placement="right"
        class="myDescription"
        width="600px"
        :visible="visible"
        :after-visible-change="afterVisibleChange"
        @close="onClose"
      >
        <a-card :bordered="false">
          <a-descriptions :title="'楼盘名称：' + (drawer.task ? drawer.task.houseName : '-')"></a-descriptions>
          <a-descriptions title="到访信息" class="evidence" v-if="drawer.status===9 || drawer.status===10">
            <a-descriptions-item label="客户姓名" span="3">{{ 
              drawer.client ? drawer.client.name : '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="客户手机号" span="3">{{
              drawer.client ? drawer.client.phone : '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="经纪人" span="3">{{
              drawer.broker ? drawer.broker.name : '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="经纪人手机号" span="3">{{
              drawer.broker ? drawer.broker.phone : '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="置业顾问" span="3">{{
              drawer.consultant ? drawer.consultant.name : '-' 
            }}</a-descriptions-item>
            <a-descriptions-item label="驻场人员" span="3">{{ 
              drawer.auditUser ? drawer.auditUser.name: '-' 
            }}</a-descriptions-item>
            <a-descriptions-item label="需求面积" span="3">{{
              `${drawer.client.areaStart || '-'} - ${drawer.client.areaEnd || '-'} m²` || '-' 
            }}</a-descriptions-item>
            <a-descriptions-item label="需求业态" span="3">{{
              drawer.client.propertyType || '-' 
            }}</a-descriptions-item>
            <a-descriptions-item label="意向强度" span="3"><a-rate :default-value="drawer.client ? drawer.client.intention : '0'" disabled /></a-descriptions-item>
            <a-descriptions-item label="报备申请时间" span="3">{{ 
              drawer.createTime || '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="预定到访时间" span="3">{{ 
              drawer.appointment || '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="到访时间" span="3">{{
              drawer.acceptTime || '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="备注信息" span="3">{{
              drawer.remark || '-' 
            }}</a-descriptions-item>
            <a-descriptions-item label="到访证据" span="3" v-if="drawer.evidence">
              <a-card hoverable style="width: 120px" v-for="item in drawer.evidence" :key="item.id" class="inlineBlock">
                <media :file="item" />
              </a-card>
            </a-descriptions-item>
          </a-descriptions>
          <a-divider style="margin-bottom: 32px" v-if="drawer.status!==9 && drawer.status!==10"/>
          <a-descriptions title="报备信息" v-if="drawer.status!==9 && drawer.status!==10">
            <a-descriptions-item label="代理商" span="3">{{ 
              drawer.agentName | textFilter
            }}</a-descriptions-item>
            <a-descriptions-item label="客户姓名" span="3">{{ 
              drawer.client ? drawer.client.name : '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="客户手机号" span="3">{{ 
              drawer.client ? drawer.client.phone : '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="经纪人" span="3">{{ 
              drawer.broker ? drawer.broker.name : '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="经纪人手机号" span="3">{{ 
              drawer.broker ? drawer.broker.phone : -''
            }}</a-descriptions-item>
            <a-descriptions-item label="需求面积" span="3">{{ 
              drawer.client ? `${drawer.client.areaStart || '-'} - ${drawer.client.areaEnd || '-'} m²` || '-' : '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="需求业态" span="3">{{ 
              drawer.client ? drawer.client.propertyType : '-' 
            }}</a-descriptions-item>
            <a-descriptions-item label="意向强度" span="3"><a-rate :default-value="drawer.client ? drawer.client.intention : '0'" disabled /></a-descriptions-item>
            <a-descriptions-item label="报备申请时间" span="3">{{ 
              drawer.createTime ? drawer.createTime : '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="预定到访时间" span="3">{{ 
              drawer.appointment ? drawer.appointment : '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="备注信息" span="3">{{ 
              drawer.remark ? drawer.remark : '-' 
            }}</a-descriptions-item>
          </a-descriptions>
          <a-divider style="margin-bottom: 32px"/>
          <a-descriptions title="日志" class="log">
          </a-descriptions>
        </a-card>
      </a-drawer>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis, Media } from '@/components'
import { queryReports } from '@/api/reports'
import { housesAll } from '@/api/house'
import { statusMap, columns } from './js/index'

export default {
  name: 'TableList',
  components: {
    STable,
    Ellipsis,
    Media
  },
  data() {
    return {
      columns,
      totalCount: 0,
      requestParameters: {},
      relevance: null,
      visible: false,
      confirmLoading: false,
      mdl: null,
      advanced: false,
      queryParam: {},
      drawer: [],
      loadData: parameter => {
        const param = {
          userId: this.userId
        }
        const params = this.paramFormat(parameter, param)
        Object.assign(params, this.queryParam)
        return queryReports(params).then(res => {
          const result = this.resFormat(res)
          console.log(result);
          this.totalCount = result.totalCount 
          return result
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      housesList: [],
      houseId: ''
    }
  },
  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      return statusMap[type].status
    }
  },
  mounted() {
    this.getHouseList()
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    },
    userId() {
      return this.$store.state.user.info.id
    }
  },
  methods: {
    reload() {
      this.$refs.table.refresh()
    },
    resetReload() {
      this.queryParam = {}
      this.reload()
    },
    getHouseList () {
      housesAll().then(res=>{
         this.housesList = res._embedded.houses
      })
    },
    handleEdit (record) {
      this.drawer = record
      console.log(this.drawer, '楼盘')
      this.visible = true
    },
    handleOk() {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          if (values.id > 0) {
            // 修改 e.g.
            new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve()
              }, 1000)
            }).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()

              this.$message.info('修改成功')
            })
          } else {
            // 新增
            new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve()
              }, 1000)
            }).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()

              this.$message.info('新增成功')
            })
          }
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    resetSearchForm() {
      this.queryParam = {
        date: this.moment(new Date())
      }
    },
    // report info
    afterVisibleChange (val) {
    },
    onClose () {
      // this.report.visible = false
      this.visible = false
      this.visible1 = false
    },
    findByKeyword () {
      this.search.visible = true
      this.$refs.table.refresh()
    },
    tableReset () {
      this.search.visible = false
      this.queryParam = {}
      this.$refs.table.refresh()
    },
    handleSelectChange (value) {
      this.queryParam.houseId = value
    },
    filterOption(input, option) {
        return (
            option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
    }
}
}
</script>

<style lang="less" scoped>
.log {
  /deep/ .ant-descriptions-item-content {
    span:first-child {
      font-weight: 700;
    }
    span:last-child {
      font-weight: 700;
      margin-left: 50px;
    }
  }
  /deep/ .ant-descriptions-item-label {
    font-weight: 700;
  }
}
.evidence {
  /deep/ .ant-card {
    width: 120px;
    display: inline-block;
    vertical-align: text-top;
    margin-right: 20px;
    margin-top: 20px;
  }
}
</style>
