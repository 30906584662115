export const columns = [
  {
    title: '楼盘名称',
    dataIndex: 'task.houseName'
  },
  {
    title: '客户',
    dataIndex: 'client.name'
  },
  {
    title: '经纪人',
    dataIndex: 'broker.name'
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    sorter: true
  },
  {
    title: '报备时间',
    dataIndex: 'updateTime',
    sorter: true
  },
  {
    title: '到访类型',
    dataIndex: 'naturalVisit',
    scopedSlots: { customRender: 'naturalVisit' }
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '操作',
    dataIndex: 'action',
    fixed: 'right',
    scopedSlots: { customRender: 'action' }
  }
]

export const statusMap = {
  0: {
    status: 'default',
    text: '待审批'
  },
  1: {
    status: 'processing',
    text: '已报备'
  },
  '-1': {
    status: 'default',
    text: '已否决'
  },
  '-2': {
    status: 'default',
    text: '已过到访保护期'
  },
  '-3': {
    status: 'default',
    text: '未到访已作废'
  },
  9: {
    status: 'processing',
    text: '已到访'
  },
  10: {
    status: 'processing',
    text: '已认购'
  }
}
